<template>
  <div>
    <div style="padding: 0 0 10px 20px">
      <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          :disabled="selection.length == 0"
          @click="batchDel"
      >删除
      </el-button
      >
    </div>
    <div class="default-table" style="padding: 0 20px">
      <el-table :data="dataList" @selection-change="selectionChange">
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column
            label="执行时间"
            prop="created_at"
            width="160"
        ></el-table-column>
        <el-table-column label="执行结果" prop="state" width="80">
          <template #default="scope">
            <span v-if="scope.row.status == '0'" style="color: #67c23a"
            ><i class="el-icon-success"></i> 成功</span
            >
            <span v-else style="color: #f56c6c"
            ><i class="el-icon-error"></i> 异常</span
            >
          </template>
        </el-table-column>
        <el-table-column
            label="执行目标"
            prop="target"
            width="200"
            :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="异常信息" prop="logs" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button @click="show(scope.row)" type="primary" link>查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :hidden="total===0"
                  :background="true"
                  :total.sync="total"
                  :page.sync="pageData.current_page"
                  :limit.sync="pageData.page_size"
                  @pagination="getList"/>
      <!--      <maTable-->
      <!--        ref="table"-->
      <!--        row-key="id"-->
      <!--        :api="api"-->
      <!--        :autoLoad="false"-->
      <!--        :params="{ orderBy: 'created_at', orderType: 'desc' }"-->
      <!--        @selection-change="selectionChange"-->
      <!--        stripe-->
      <!--      >-->

      <!--      </maTable>-->
    </div>

    <el-drawer
        title="异常信息"
        :visible.sync="logsVisible"
        :size="500"
        direction="rtl"
        append-to-body
        destroy-on-close
    >
      <div style="padding: 0 20px 20px 20px">
        <pre>{{ log == "" ? "无异常信息" : log }}</pre>
      </div>
    </el-drawer>
  </div>
</template>

<script>

export default {
  data() {
    return {
      selection: [],
      logsVisible: false,
      dataList: [],
      // api: {
      //   list: this.$api.getCrontabLogPageList,
      // },
      queryParams: {
        crontab_id: undefined,
      },
      log: "",
      crontab_id: "",
      total: 0,
      pageData: {
        current_page: 1,
        page_size: 15
      }
    };
  },

  methods: {
    show(row) {
      // this.$notify.success('查看')
      this.logsVisible = true;
      this.log = row.exception_info;
    },

    setData(row) {
      this.crontab_id = row.id;
      this.queryParams.crontab_id = row.id;
      this.getList()
      // this.$refs.table.upData(this.queryParams);
    },
    handleQuery() {
      this.pageData.current_page = 1;
      this.getList()
    },
    async getList() {
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData)
      let {list, pages} = await this.$api.getCrontabLogPageList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list || []
      })
      this.pageData.current_page = pages.current_page || 1;
      this.pageData.page_size = pages.page_size || 1;
      this.total = pages.total
    },
    handleSearchCondition() {
      let condition = {}
      if (this.queryParams.crontab_id) {
        condition.crontab_id = this.queryParams.crontab_id
      }
      return condition
    },

    //批量删除
    async batchDel() {
      await this.$confirm(`确定删除选中的 ${this.selection.length} 项吗？`, "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading();
        let ids = [];
        this.selection.map((item) => ids.push(item.id));
        this.$api.deleteCrontabLog(ids.join(",")).then(() => {
          this.handleQuery()
          // this.$refs.table.upData({ crontab_id: this.crontab_id });
        });
        loading.close();
        this.$message.success("操作成功");
      });
    },

    //表格选择后回调事件
    selectionChange(selection) {
      this.selection = selection;
    },
  },
};
</script>

<style scoped>
pre {
  font-size: 12px;
  color: #ccc;
  padding: 20px;
  background: #333;
  font-family: consolas;
  line-height: 1.5;
  overflow: auto;
  border-radius: 4px;
}

.el-container {
  flex-direction: column;
}
</style>
